<template>
  <v-container fluid class="bg-image">
    <v-row justify="center" align="center" class="full-height">
      <v-col lg="5" md="7" sm="9" cols="11" class="d-flex justify-center">
        <v-card elevation="2" class="login-box py-8">
          <div>
            <img src="../assets/img/logo_jangadeiro.png" />
          </div>
          <div class="login-description">
            <p>Uma só conta para todos os serviços da Jangadeiro!</p>
          </div>

          <v-form
            ref="form"
            v-model="valid"
            class="login-form mx-5"
            lazy-validation
            @submit.prevent="login()"
          >
            <v-text-field
              v-model="user.username"
              :rules="[rules.required]"
              label="Nome de usuário ou e-mail"
              append-icon="mdi-account"
              rounded
              outlined
              required
            />

            <v-text-field
              v-model="user.password"
              :rules="[rules.required]"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Senha"
              rounded
              outlined
              required
              @click:append="showPassword = !showPassword"
            />

            <v-row justify="center" no-gutters>
              <v-col class="d-flex justify-center" cols="12">
                <v-btn
                  block
                  :disabled="!valid || loading"
                  color="primary"
                  class="login-btn"
                  large
                  rounded
                  type="submit"
                  @click="login()"
                >
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="25"
                    width="3"
                    color="white"
                  />
                  <span v-else class="white--text">Entrar</span>
                </v-btn>
              </v-col>

              <v-col cols="12" class="d-flex flex-column align-center mt-4">
                <router-link to="/recuperar-senha" class="text-link">
                  Esqueceu sua senha? <span>Recupere aqui!</span>
                </router-link>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-divider />
              </v-col>

              <v-col cols="12" class="d-flex flex-column align-center mt-4">
                <router-link to="/cadastro" class="text-link">
                  Ainda não tem uma conta? <span>Cadastre-se!</span>
                </router-link>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-row justify="center" align="center">
                  <v-col cols="3">
                    <v-divider />
                  </v-col>
                  <v-col cols="6" class="text-link">ou entre com</v-col>
                  <v-col cols="3">
                    <v-divider />
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="mt-4">
                <v-row justify="center" align="center">
                  <v-col cols="8">
                    <v-btn
                      large
                      color="primary"
                      outlined
                      class="social-btn"
                      @click="googleSignIn()"
                    >
                      <v-progress-circular
                        v-if="loadingGoogle"
                        indeterminate
                        size="25"
                        width="3"
                        color="primary"
                      />
                      <div v-else class="d-flex justify-center align-center">
                        <img src="../assets/img/google.png" alt="Google logo" />
                        Google
                      </div>
                    </v-btn>
                  </v-col>
                  <!-- <v-col cols="6">
                    <v-btn
                      large
                      color="primary"
                      outlined
                      class="social-btn"
                      @click="facebookSignUp()"
                    >
                      <img
                        src="../assets/img/facebook.png"
                        alt="Facebook logo"
                      />
                      Facebook
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Auth } from "aws-amplify";
import { urlParams } from "../utils/helpers";

export default {
  data() {
    return {
      loading: false,
      loadingGoogle: false,
      valid: true,
      showPassword: false,
      authenticatedUser: null,
      rules: {
        required: (value) => !!value || "Este campo é obrigatório.",
      },
      user: {
        username: "",
        password: "",
      },
    };
  },
  async mounted() {
    await this.verifyIsLogged();
    this.verifyLocalStorage();
    this.iframeParams();
    this.verifyIframeRequests();
    this.verifyIsGoogleAuth();
  },
  computed: {
    isGoogleFramedSignIn: {
      get() {
        return this.$store.state.isGoogleFramedSignIn;
      },
      set(value) {
        this.$store.commit("SET_GOOGLE_FRAMED_SIGN_IN", value);
      },
    },
  },
  methods: {
    login() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        Auth.signIn({
          username: this.user.username,
          password: this.user.password,
        })
          .then((data) => {
            this.loading = false;
            this.$store.commit("SET_USER", this.getUserProfile(data));
            this.$router.push("/perfil");
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    googleSignIn() {
      const iframeRequestType = this.$store.state.params.type;
      if (iframeRequestType === urlParams.SIGN_IN) {
        this.loadingGoogle = true;
        var windowUrl = window.location.href;
        windowUrl = windowUrl.split("?")[0];

        const url = `${windowUrl}?type=${urlParams.GOOGLE_SIGN_IN}`;
        window.open(url, "_blank").focus();
      } else {
        Auth.federatedSignIn({ provider: "Google" });
      }
    },
    facebookSignUp() {
      Auth.federatedSignIn({ provider: "Facebook" });
    },
    async verifyIframeRequests() {
      const iframeRequestType = this.$store.state.params.type;
      var user = this.authenticatedUser;
      // console.log("From sso frontend: " + iframeRequestType);
      user = user ? this.getUserProfile(user) : null;

      if (
        iframeRequestType === urlParams.RETRIEVE_AUTHENTICATED_USER_AND_CLOSE &&
        user
      ) {
        const {
          accessToken: { jwtToken },
        } = await Auth.currentSession();

        let userData = {
          ...user,
          jwtToken,
        };
        this.iframeReturnUserData(userData);
        this.iframeClose();
      }

      if (
        iframeRequestType === urlParams.RETRIEVE_AUTHENTICATED_USER_AND_CLOSE &&
        !user
      ) {
        this.iframeClose();
      }

      if (iframeRequestType === urlParams.LOGOUT && user) {
        this.$store.commit("SET_USER", null);
        this.$store.commit("SET_GOOGLE_FRAMED_SIGN_IN", false);

        await Auth.signOut();

        this.ls_setGoogleSuccess(false);
        this.iframeLogout();
        this.iframeClose();
      }

      if (!iframeRequestType && user) {
        setTimeout(() => {
          this.loading = false;
          this.$router.push("/perfil");
        }, 500);
      }
    },
    verifyIsGoogleAuth() {
      const iframeRequestType = this.$store.state.params.type;

      if (iframeRequestType === urlParams.GOOGLE_SIGN_IN) {
        this.ls_setGoogleSuccess(false);
        this.isGoogleFramedSignIn = true;
        this.googleSignIn();
      }
    },
    verifyLocalStorage() {
      this.ls_attachListener(this.watchGoogleSuccess);
    },
    async watchGoogleSuccess() {
      const value = this.ls_getGoogleSuccess();
      if (value) {
        var user;
        try {
          user = await Auth.currentAuthenticatedUser();
        } catch (error) {
          console.log(error);
        }

        if (user) {
          var profile = this.getUserProfile(user);

          const {
            accessToken: { jwtToken },
          } = await Auth.currentSession();

          profile = {
            ...profile,
            jwtToken,
          };

          this.iframeReturnUserData(profile);
        }
        this.iframeClose();
      }
    },
    async verifyIsLogged() {
      try {
        this.authenticatedUser = await Auth.currentAuthenticatedUser();
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style scoped>
.login-box {
  background-color: #fff;
  max-width: 500px;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
}

.login-description p {
  text-align: center;
  margin-bottom: 40px;
  color: #a3a3a3;
}

.login-btn {
  font-weight: bold;
  letter-spacing: 0 !important;
}

.text-link {
  text-decoration: none;
  color: #a3a3a3;
}

.text-link span {
  font-weight: bold;
  color: #008cd1;
}

.social-btn {
  width: 100%;
  letter-spacing: 0 !important;
  font-weight: bold;
}

.social-btn img {
  width: 25px;
  margin-right: 10px;
}
</style>
